// Btn to open menu
#index {
  @screen m {
    .headertop {
      @apply absolute left-0 top-0 w-full;
      background: white;
    }

    .flash-infos {
      position: absolute;
      top: 95px;
      left: 0;
      width: 100%;
    }
  }
}

#menu-btn {
  @apply pr-2 mr-2;
  background: none;
  border: none;
  transition: 0.4s opacity;
  @apply ease-out-cubic;

  &:hover {
    opacity: 0.5;
  }

  &::after {
    @apply absolute top-0 right-0 block;
    content: '';
    height: 20px;
    width: 1px;
    background: #7d7d7d;
    margin-left: 20px;
  }
}

.links_top_content a {
  transition: 0.4s opacity;
  @apply ease-out-cubic;

  &:hover {
    opacity: 0.5;
  }
}

// Menu
.menu {
  @apply fixed top-0 left-0 w-full h-full;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  pointer-events: none;
  transition: 0.4s opacity;
  @apply ease-out-cubic;

  @screen m-md {
    top: 71px;
  }
}

.menu__wrapper {
  width: 100%;
  max-width: 424px;
  transform: translateX(-150%);
  transition: 0.6s transform;
  @apply ease-out-cubic;
}

.menu__panel {
  @apply bg-white px-2 pt-2 pb-7 static;
  overflow: scroll;
  height: 100vh;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @screen m {
    @apply pl-11 pr-0;
  }
}

.menu__title {
  @apply block text-light-black text-medium font-semibold uppercase py-2 px-1/5;
  line-height: 32px;
  border-bottom: 1px solid theme('colors.black');

  @screen m {
    @apply text-large mb-4 py-0 px-0;
    border-bottom: none;
  }

  @screen m-md {
    .menu__submenu-panel & {
      &::before {
        content: '';
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 492 492' style='enable-background:new 0 0 492 492;' xml:space='preserve'%3E%3Cpath d='M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        margin-right: 20px;
        width: 14px;
        height: 14px;
        transform: translateY(1px);
      }
    }
  }
}

.menu__title--products {
  @screen m-md {
    @apply hidden;
  }
}

.menu__list {
  @apply static;
}

.menu__item {
  @apply static;

  @screen m {
    &:hover {
      > .menu__submenu-panel {
          display: block;
          pointer-events: initial;
      }

      .menu__link--2 {
        &::before {
          display: block;
        }
      }
    }
  }
}

.menu__link {
  @apply text-body uppercase text-light-black py-1 block static;
  transition: 0.3s opacity ease-in-out;
  line-height: 26px;

  .menu__item--haschildren & {
    &::before {
      content: '';
      z-index: 10;
      position: absolute  ;
      left: 100%;
      display: none;
      width: 9px;
      height: 19px;
      background: url(#{$svg-dir}menu-chevron.svg) no-repeat top left;
    }
  }

  span {
    &::after {
      @apply bg-light-black block;
      content: '';
      height: 2px;
      width: 100%;
      transform: scaleX(0);
      transform-origin: right;
      transition: 0.4s transform;
      @apply ease-out-cubic;
    }
  }

  @screen m {
    @apply text-regular;

    &:hover {
      span {
        &::after {
          transform-origin: left;
          transform: scaleX(1);
        }
      }
    }
  }

  @screen m-md {
    @apply font-bold;
  }
}

.menu__link--3,
.menu__link--4 {
  @screen m-md {
    @apply px-1/5;
  }
}

.menu__mobile-link {
  @apply absolute text-large text-black h-full;
  font-family: Arial, sans-serif;
  right: 0px;
  width: 90px;
  flex-flow: row-reverse;
}

.menu__item--haschildren {
  @screen m-md {
    @apply relative;
  }
}

.menu__close {
  @apply  hidden;

  @screen m {
    @apply absolute block;
    left: 40px;
    top: 57px;
    border: none;
    background: none;
    transition: 0.4s transform;
    @apply ease-out-cubic;

    svg {
        display: block;
    }

    &:hover {
      transform: rotate(180deg);
    }
  }
}

.menu__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.menu__submenu-panel {
  @apply fixed top-0 bg-white pb-7;
  left: 0;
  width: 490px;
  height: 100vh;
  overflow: scroll;
  pointer-events: none;
  transform: translateX(-100%);
  transition: 0.4s transform;
  @apply ease-out-cubic;

  @screen m {
    @apply hidden px-8 absolute top-0 bg-white pt-12 pb-7;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    transform: translateX(0);
    left: 100%;
  }
}

.menu__logo {
  @apply hidden;

  @screen m {
    @apply block;
  }
}

.submenu-open {
  z-index: 10;

  .menu__submenu-panel {
    @apply block left-0;
    top: 0;
    pointer-events: initial;
    transform: translateX(0);
  }
}

// Hotfix 1.5.1 temporaire pour corrigé souci à la MEL
// #block_top_menu {
//     z-index: 4;
//     border-top: 1px #3f6c7d solid;
//     background: rgba(255, 255, 255, 0.9);
//     position: relative;
//     min-height: 43px;
// }

// @media (min-width: 1200px) {
//     #block_top_menu.fixe {
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         background: white;
//         z-index: 9999;
//     }
// }

// @media (max-width: 767px) {
//     #block_top_menu {
//         z-index: 5;
//         border-top: none;
//         height: 0;
//         min-height: 0;
//     }
//     #block_top_menu .sf-menu {
//         margin-top: -70px;
//     }
// }

.menu__item--11,
.menu__item--13 {
    position: relative;

    a {
        display: inline-block !important;
        position: relative;
        background: none !important;
        color: black !important;
        font-weight: 300;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0px;
            bottom: 12px;
            height: 8px;
            width: 100%;
            background: theme('colors.pink');
            z-index: -1;
            transform-origin: left;
            transition: 0.2s transform $out-cubic;
        }

        &:hover {
            &::before {
                transform: scaleX(0);
                transform-origin: right;
            }
        }
    }
}

.liens_header {
  display: none;

  @screen m {
    display: block;
  }
}

#headerlogo {
  @screen m-md {
    margin: 0 auto;
    padding: 15px 0;
    width: 58px;
  }
}

#header .shopping_cart {
  @screen m-md {
    float: none;
    margin: 0 !important;
  }
}

#search_block_top {
  #search_query_top {
    @screen m-md {
      margin-top: 0 !important;
    }
  }

  &.new_search_block {
    @screen s-md {
      @apply py-1;
      margin-bottom: 0;
    }
  }
}

.headerright {
  @screen m-md {
    top: 50%;
    transform: translateY(-50%);
  }
}

.myaccount_top {
  @screen s-md {
    margin: 0;
  }

  @screen m-md {
    padding: 0;
    margin: 0 5px;

    a {
      display: block !important;
    }
  }
}

.cart_top {
  @screen m-md {
    width: auto;
  }
}

.burger {
  @apply absolute flex items-center;
  background: none;
  border: none;
  top: 50%;
  padding: 0;
  left: 15px;
  transform: translateY(-50%);
}

.burger__lines {
  @apply block mr-1;
  width: 16px;
  height: 13px;
}

.burger__line {
  @apply bg-black left-0 absolute w-full;
  height: 1px;
}

.burger__line--top {
  @apply top-0;
}

.burger__line--mid {
  top: 6px;
}

.burger__line--bot {
  @apply bottom-0;
}

.burger__close-label {
  display: none;
}

.menu__mobile-links {
  border-top: 1px solid #E3E3E3;
}

.menuIsOpen {
  overflow: hidden;
  position: fixed;
  width: 100%;

  #header {
    z-index: 2147483002; // over intercom widget
  }

  .onglet_contact {
    display: none;
  }

  .menu {
    opacity: 1;
    pointer-events: initial;
  }

  .menu__wrapper {
    transition-delay: 0.1s;
    transform: translateX(0);
  }

  .preheader {
    opacity: 0;
    visibility: hidden;
  }

  .burger__close-label {
    display: block;
  }

  .burger__menu-label {
    display: none;
  }

  .burger__line--top {
    transform: rotate(45deg);
    top: 6px;
  }

  .burger__line--mid {
    opacity: 0;
  }

  .burger__line--bot {
    transform: rotate(-45deg);
    top: 6px;
  }
}

.homeslider-description {
    display: none !important;
}