.product-name {
    @apply text-light-black leading-body font-sans text-body;

    @screen s {
        @apply text-regular;
    }
}

.product-price {
    @apply text-light-black leading-body font-sans text-medium;
}

// TITLE
.title-capital {
    @apply font-capital text-light-black leading-regular text-large uppercase relative mb-2;

    strong {
        z-index: 1;
        &::before {
            @apply bg-pink block absolute z-limbo;
            content: "";
            height: 14px;
            left: -5px;
            bottom: 0;
            width: calc(100% + 10px);
        }
    }
    @screen s {
        @apply text-capital leading-capital mb-4;

        strong {
            &::before {
                height: 22px;
            }
        }
    }
}

.title-medium {
    @apply text-body text-medium uppercase;
    font-weight: 600;
    color: #555454;
    line-height: normal;
    margin-bottom: 30px;

    &::after {
        content:"";
        display: block;
        background: currentColor;
        width: 40px;
        height: 1px;
        margin: 13px auto 0 auto;
    }
}

// Buttons
.social-connect__btn {
    @apply uppercase text-body text-black;
    padding: 10px 20px;
    border:1px solid black;
}

.product-tag {
    @apply bg-white block absolute px-1 uppercase;
    font-size: 10px;
    letter-spacing: 0.5px;
    z-index: 10;
    border: 1px solid black;
    border-radius: 3px;
    color: black;
    pointer-events: none;
    top: 13px;
    left: 10px;
    height: 24px;
    line-height: 24px;


    &.product-tag--product-page {
        top: 10px;
        left: 0px;
    }

    @screen s {
        &.product-tag--product-page {
            top: 30px;
            left: 30px;
        }
    }
}

.container--large {
    max-width: none;
    padding: 0;

    @screen s {
        padding-left: 20px;
        padding-right: 20px;
    }
}