.sm-content {
    p {
        @apply text-body leading-body font-sans text-light-black;
    }

    @screen s {
        p {
            @apply text-regular leading-regular;
        }
    }
}