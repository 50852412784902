// Border button
.btn-default-sm {
    @apply px-2 py-1 uppercase text-body;
    transition: 0.3s background $out-cubic, 0.3s border-color $out-cubic;

    @screen s {
        @apply px-3 py-1/5;
    }
}

.btn-default-sm--white {
    @apply text-white;
    border: 1px solid theme('colors.white');
    background: none;

    &:hover {
        @apply bg-white text-black;
    }
}

.btn-default-sm--red {
    @apply bg-red text-white;
    border: 1px solid theme('colors.red');

    &:hover {
        @apply bg-black text-white;
        border-color: theme('colors.white');
    }
}

.btn-default-sm--black {
    @apply text-light-black;
    border: 1px solid theme('colors.light-black');
    background: none;

    &:hover {
        @apply bg-red text-white;
        border-color: theme('colors.red');
    }
}

// Underline button
.btn-under-sm {
    @apply text-regular font-capital uppercase;
    transition: 0.3s color $out-cubic;
    padding: 0;

    &::after {
        content: '';
        height: 1px;
        width: 100%;
        background: currentColor;
        transform-origin: left;
        transition: 0.3s transform $out-cubic, 0.3s background $out-cubic;
        position: absolute;
        bottom: -5px;
        left: 0;
    }

    &:hover {
        &::after {
            transform: scaleX(0);
            transform-origin: right;
        }
    }
}

.btn-under-sm--red {
    @apply text-red;
}