@import 'tailwindcss/utilities';
@import 'tailwindcss/components';

.container--sm {
  &::before,
  &::after {
    display: none;
  }
}

.sm-container {
  @apply mx-auto w-full;
  max-width: 1280px;
}

.sm * {
  @apply relative;
}

#order-detail {
  #left_column,
  #LIST_TRACKING {
    display: none;
  }

  #center_column {
    float: none;
    margin: 0 auto;
  }
}

.svg-full {
  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

@import "config";
@import "styleguide";

@import "components/content";
@import "components/buttons";
@import "components/order-steps";
@import "components/blocktopmenu";
@import "components/creation-form";