.sm-step {
    position: relative;
    width: 230px;

    &::before {
        content: '';
        position: absolute;
        top: 16px;
        left: 30px;
        display: block;
        background: #d2d2d2;
        height: 1px;
        width: calc( 100% - 60px );
        z-index: 1;
    }

    li {
        width: 30px;
        z-index: 2;
    }

    @screen s {
        width: 350px;

        &::before {
            top: 17px;
        }
        
        li {
            width: 65px;
        }
    }

    @screen m {
        width: 400px;
    }
}

.step__number {
    @apply font-bold text-white block text-body;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #d2d2d2;
    margin: 0 auto;

    @screen s {
        margin: 0 auto 5px auto;
    }
}

.step__current {
    .step__number {
        background: #46508E;
    }
}

.step__done {
    .step__number {
        background: #8b8a8a;
    }
}

.step__label {
    @apply text-small;
}