/**
 * Account creation form
 */
.sm-newsletter,
.sm-opt-in {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  p {
    font-weight: 700;
  }
}

.sm-newsletter {
  margin-bottom: 1rem;
}
